import React, { useEffect, useState } from "react";

function More({ id, cols, setBag, mores, savedMores }) {
  const [more, setMore] = useState();
  const [bagged, setBagged] = useState(false);
  const baseURL = process.env.REACT_APP_API_URL;

  const checkBag = () => {
    if (savedMores.indexOf(id) > -1) {
      setBagged(true);
    } else {
      setBagged(false);
    }
  };

  useEffect(() => {
    setMore(mores[id]);
    checkBag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, savedMores]);

  if (Object.keys(mores).length === 0) return null;

  if (!more) return null;
  if (!cols) return null;

  return (
    <div className={"carousel-cell size-" + 12 / cols}>
      <div className="row guide-action-box">
        <div className="col-12 col-md-6">
          <a href={more.url} target="_blank" rel="noreferrer">
            <figure>
              <img src={baseURL + more.img} alt={more.alt} />
            </figure>
          </a>
        </div>
        <div className="col-12 col-md-6">
          <h3>{more.title}</h3>
          <p>{more.text}</p>
          <p>
            <a
              className="more-link"
              href={more.url}
              target="_blank"
              rel="noreferrer"
            >
              {more.link_text}
            </a>
          </p>
          {!bagged && (
            <button
              type="button"
              className="btn btn--rounded btn--orange bordered"
              onClick={() => {
                setBag(id, "more", true);
                checkBag();
              }}
            >
              Auf die Leseliste
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22">
                <path d="M12.75 22a.739.739 0 01-.75-.728v-.727H6v.727a.739.739 0 01-.75.728h-1.5a.739.739 0 01-.75-.728v-.727h-.75A2.216 2.216 0 010 18.364V8.182A2.216 2.216 0 012.25 6H5V2.25A2.25 2.25 0 017.25 0h4.5A2.25 2.25 0 0114 2.25V6h1.75A2.216 2.216 0 0118 8.182v10.182a2.216 2.216 0 01-2.25 2.182H15v.727a.739.739 0 01-.75.727zm-1-16V2.25h-4.5V6z" />
              </svg>
            </button>
          )}
          {bagged && (
            <button
              type="button"
              className="btn btn--rounded btn--white bordered"
              onClick={() => {
                setBag(id, "more", false);
                checkBag();
              }}
            >
              Von der Leseliste entfernen
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22">
                <path d="M12.75 22a.739.739 0 01-.75-.728v-.727H6v.727a.739.739 0 01-.75.728h-1.5a.739.739 0 01-.75-.728v-.727h-.75A2.216 2.216 0 010 18.364V8.182A2.216 2.216 0 012.25 6H5V2.25A2.25 2.25 0 017.25 0h4.5A2.25 2.25 0 0114 2.25V6h1.75A2.216 2.216 0 0118 8.182v10.182a2.216 2.216 0 01-2.25 2.182H15v.727a.739.739 0 01-.75.727zm-1-16V2.25h-4.5V6z" />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default More;
