import React from "react";

import "./scss/styles.scss";
import ModalView from "./components/views/ModalView";

function App() {
  return (
    <div className="App">
      <ModalView />
    </div>
  );
}

export default App;
