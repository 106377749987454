import React, { useState, useEffect } from "react";
import { Collapse } from "@material-ui/core";

import Rating from "./Rating";
import ResultReview from "./ResultReview";
import Tip from "./Tip";
import More from "./More";
import Person from "./Person";

function QuestResult({
  titleClr,
  data,
  questions,
  answers,
  setAnswers,
  setBag,
  mores,
  tips,
  savedTips,
  savedMores,
  icon,
  winWidth,
  routeFinished,
  category,
  setCurrentCat,
  allAnswers,
  setAllAnswers,
}) {
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    routeFinished(category);
    if (answers[0] !== undefined) {
      let answerObject = allAnswers;
      answerObject[category] = answers;
      setAllAnswers(answerObject);
    } else {
      let answerObject = allAnswers[category];
      setAnswers(answerObject);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="guide-result__inner">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="guide-result__header">
              <div className="guide-result__header-left">
                <div className={"title title-clr-" + category}>
                  <h1>{titleClr}</h1>
                </div>
                <div className="title">
                  <h1>{data.title}</h1>
                </div>
              </div>
              <div className="guide-result__header-right">
                <img
                  src={baseURL + icon.url}
                  alt={icon.alternative}
                  title={icon.title}
                />
              </div>
            </div>
            <div
              type="button"
              className={open ? "show-more active" : "show-more"}
              onClick={() => {
                setOpen(!open);
              }}
            >
              Informationen zu unseren Sterne-Bewertungen
              <span className="btn--show-more"></span>
            </div>
          </div>
        </div>
        <Collapse in={open}>
          <div className="info">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="guide-result__ratings overal highlighted">
                  <div className="guide-result__rating">
                    <Rating fill={3} />
                    <span>Die Handlung hat eine große positive Wirkung.</span>
                  </div>
                  <div className="guide-result__rating">
                    <Rating fill={2} />
                    <span>
                      Die Handlung hat eine mittlere positive Wirkung.
                    </span>
                  </div>
                  <div className="guide-result__rating">
                    <Rating fill={1} />
                    <span>Die Handlung hat eine geringe positive Wirkung.</span>
                  </div>
                  <div className="guide-result__rating">
                    <Rating fill={0} />
                    <span>Die Handlung hat eine negative Wirkung.</span>
                  </div>
                  <div className="guide-result__rating">
                    <Rating fill={-1} />
                    <span>Es gibt keinen Zusammenhang zur Handlung.</span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                {/* <p>{data.ratingText}</p> */}
                <p className="text-orange">
                  Die Sterne geben an, wie groß die Wirkung ist, wenn Sie die
                  jeweils genannte Handlung durchführen.
                </p>
                <p className="text-orange">
                  Jedes Handeln hat Auswirkungen auf die ökologische, die
                  wirtschaftliche und die soziale Situation vor Ort.
                </p>
              </div>
            </div>
          </div>
        </Collapse>

        <div className="guide-result-reviews">
          {questions.map((item, index) => (
            <ResultReview
              key={item.uid}
              activeIndex={activeIndex}
              index={index}
              setActiveIndex={setActiveIndex}
              data={item}
              answer={answers[index]}
              winWidth={winWidth}
            />
          ))}
        </div>

        {data.tips && data.tips[0] !== "" && (
          <div className="guide-result__tips">
            <div className="title text-center">
              <h2>Tipps für Ihre nächste Reise</h2>
            </div>
            {data.tips.length === 1 && (
              <div className="main-carousel row">
                {data.tips.map((id, index) => (
                  <Tip
                    id={id}
                    key={index}
                    cols={2}
                    setBag={setBag}
                    tips={tips}
                    savedTips={savedTips}
                  />
                ))}
              </div>
            )}
            {data.tips.length === 2 && (
              <div className="main-carousel row">
                {data.tips.map((id, index) => (
                  <Tip
                    id={id}
                    key={index}
                    cols={2}
                    setBag={setBag}
                    tips={tips}
                    savedTips={savedTips}
                  />
                ))}
              </div>
            )}
            {data.tips.length > 2 && (
              <div className="main-carousel row">
                {data.tips.map((id, index) => (
                  <Tip
                    id={id}
                    key={index}
                    cols={3}
                    setBag={setBag}
                    tips={tips}
                    savedTips={savedTips}
                  />
                ))}
              </div>
            )}
          </div>
        )}

        {data.people && data.people.length > 0 && (
          <React.Fragment>
            {data.people.length === 1 && (
              <div className="main-carousel row d-flex">
                {data.people.map((item, index) => (
                  <Person key={index} data={item} cols={1} />
                ))}
              </div>
            )}
            {data.people.length === 2 && (
              <div className="main-carousel row d-flex">
                {data.people.map((item, index) => (
                  <Person key={index} data={item} cols={2} />
                ))}
              </div>
            )}
            {data.people.length > 2 && (
              <div className="main-carousel row d-flex">
                {data.people.map((item, index) => (
                  <Person key={index} data={item} cols={3} />
                ))}
              </div>
            )}
          </React.Fragment>
        )}

        {data.factbox && data.factbox.uid && (
          <div className="row m-sm-0">
            <div className="guide-result__list-article col-12">
              <div className="title text-center title--orange ">
                <h2>{data.factbox.title}</h2>
              </div>
              <div className="title text-center">
                <h3>{data.factbox.beginning}</h3>
              </div>
              <div
                className="fact-content"
                dangerouslySetInnerHTML={{ __html: data.factbox.facts }}
              ></div>
            </div>
          </div>
        )}

        {data.more && data.more[0] !== "" && (
          <div className="guide-result__action-boxes">
            <div className="title text-center">
              <h2>Mehr zum Thema</h2>
            </div>
            <div className="main-carousel row">
              {data.more.map((id, index) => (
                <More
                  id={id}
                  cols={1}
                  key={index}
                  setBag={setBag}
                  mores={mores}
                  savedMores={savedMores}
                />
              ))}
            </div>
          </div>
        )}

        <div className="d-flex justify-content-center end-button">
          <button
            type="button"
            className="btn btn--rounded btn--orange flex-centered"
            onClick={() => {
              setCurrentCat(0);
            }}
          >
            Weiter zur Themenübersicht
          </button>
        </div>
      </div>
    </div>
  );
}

export default QuestResult;
