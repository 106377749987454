import React, { useState, useEffect, createRef } from "react";
import { Fade } from "@material-ui/core";
import { isDesktop } from "react-device-detect";

import CatMapDesktop from "../CatMapDesktop";
import CatMapTablet from "../CatMapTablet";
import CatMapMobile from "../CatMapMobile";
import QuestRoute from "../QuestRoute";
import BagContent from "../BagContent";

import { loadMores, loadTips, loadCategories } from "../../api/client";

const tourismWatch = require("../../assets/icons/logo-tourism-watch.svg");
const bottomLogo = require("../../assets/icons/logo-bfdw.svg");
const suitcase = require("../../assets/icons/suitcase.svg");
const suitcaseOutlined = require("../../assets/icons/suitcase-outlined.svg");

function ModalView(props) {
  const [show, setShow] = useState(false);
  const [bagOpen, setBagOpen] = useState(false);
  const [showBag, setShowBag] = useState(false);
  const [showBckTop, setShowBckTop] = useState(false);
  const [bagAnimation, setBagAnimation] = useState(false);
  const [routeIn, setRouteIn] = useState(false);
  const [compIn, setCompIn] = useState(false);
  const [bottomBagVisible, setBottomBagVisible] = useState(true);

  const [netErrors, setNetErrors] = useState([]);
  const [savedTips, setSavedTips] = useState([]);
  const [savedMores, setSavedMores] = useState([]);

  const [curNumber, setCurNumber] = useState(-1);
  const [winWidth, setWinWidth] = useState(0);
  const [currentCat, setCurrentCat] = useState(0);
  const [bagNumber, setBagNumber] = useState(0);
  const [nextNum, setNextNum] = useState(0);
  const [footerSafety, setFooterSafety] = useState(10);

  const [visited, setVisited] = useState({});
  const [allAnswers, setAllAnswers] = useState({});
  const [categories, setCategories] = useState({});
  const [tips, setTips] = useState({});
  const [mores, setMores] = useState({});

  const componentRef = createRef();
  const debug = false;

  const scrollToTop = () => {
    if (componentRef.current) {
      componentRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const jumpToTop = () => {
    if (componentRef.current) componentRef.current.scrollTo(0, 0);
  };

  const openBag = () => {
    jumpToTop();
    setBagOpen(true);
    setCompIn(false);
    setShowBag(true);
  };

  const closeBag = () => {
    setBagOpen(false);
    setCompIn(true);
  };

  const resetCat = () => {
    setRouteIn(false);
    setCompIn(false);
    setCat(0);
  };

  const setCat = (num) => {
    setRouteIn(false);
    setCompIn(false);
    setCurNumber(-1);
    setNextNum(num);
  };

  const handleResize = () => {
    if (window.innerWidth > 0) {
      setWinWidth(window.innerWidth);
    }
  };

  const pushDataLayer = (identifier) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "answered",
      answer: identifier,
    });
  };

  const addError = (err) => {
    let newErrors = netErrors;
    let index = newErrors.indexOf(err);
    if (index === -1) {
      newErrors.push(err);
    }
    setNetErrors(newErrors);
  };

  const routeFinished = (num) => {
    let newVisited = visited;
    // if (categories[num].gtmIdentifier && categories[num].gtmIdentifier !== "") {
    //   pushDataLayer(categories[num].gtmIdentifier);
    // }
    newVisited[num] = true;
    setVisited(newVisited);
  };

  const setBag = (id, type, add) => {
    if (add) {
      if (type === "tip") {
        if (savedTips.indexOf(id) === -1) {
          setBagAnimation(true);
          let newTips = savedTips;
          newTips.push(id);
          setBagNumber(bagNumber + 1);
          setSavedTips(newTips);
          setBagAnimation(true);
          setTimeout(() => {
            setBagAnimation(false);
          }, 1000);
        }
      }
      if (type === "more") {
        if (savedMores.indexOf(id) === -1) {
          let newMores = savedMores;
          newMores.push(id);
          setBagNumber(bagNumber + 1);
          setSavedMores(newMores);
          setBagAnimation(true);
          setTimeout(() => {
            setBagAnimation(false);
          }, 1000);
        }
      }
    } else {
      if (type === "tip") {
        if (savedTips.indexOf(id) > -1) {
          let newTips = savedTips;
          newTips.splice(newTips.indexOf(id), 1);
          setBagNumber(bagNumber - 1);
          setSavedTips(newTips);
        }
      }
      if (type === "more") {
        if (savedMores.indexOf(id) > -1) {
          let newMores = savedMores;
          newMores.splice(newMores.indexOf(id), 1);
          setBagNumber(bagNumber - 1);
          setSavedMores(newMores);
        }
      }
    }
  };

  const adjustFooterSafety = () => {
    let padding = 10;
    let footerHeight = document.getElementById(
      "modal-online-tool-footer"
    ).offsetHeight;
    let scrollTop = componentRef.current.scrollTop;
    let scrollHeight = componentRef.current.scrollHeight;
    let clientHeight = componentRef.current.clientHeight;
    let footerSafetyNum =
      scrollTop + footerHeight - (scrollHeight - clientHeight);
    // console.log(scrollTop);
    // console.log(scrollTop + footerHeight - (scrollHeight - clientHeight));

    if (footerSafetyNum > padding) {
      setFooterSafety(footerSafetyNum + padding);
    } else {
      setFooterSafety(padding);
    }
  };

  const scrollListener = () => adjustFooterSafety();

  useEffect(() => {
    adjustFooterSafety();
    if (winWidth <= 575 && curNumber === 99) {
      setBottomBagVisible(false);
    } else {
      setBottomBagVisible(true);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winWidth, curNumber]);

  useEffect(() => {
    setCompIn(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCat]);

  useEffect(() => {
    // ================================================
    // Back handler
    // ================================================
    window.history.pushState(null, null, null);

    // ================================================

    // ================================================
    // Scroll handler
    // ================================================
    componentRef.current.onscroll = (e) => {
      if (e.srcElement.scrollTop > 100) {
        setShowBckTop(true);
      } else {
        setShowBckTop(false);
      }
    };
    // ================================================

    // ================================================
    // Resize handler
    // ================================================
    window.addEventListener("resize", handleResize);
    window.setInterval(() => {
      handleResize();
    }, 500);
    // ================================================

    loadMores()
      .then((response) => {
        // if (response.data.error) addError(response.data.error);
        if (response.data.data) {
          let obj = response.data.data.reduce(
            (acc, cur) => ({ ...acc, [cur.uid]: cur }),
            {}
          );
          if (debug) console.log(obj);
          setMores(obj);
        }
      })
      .catch((error) => {
        addError(error);
      });
    loadTips()
      .then((response) => {
        // if (response.data.error) addError(response.data.error);
        if (response.data.data) {
          let obj = response.data.data.reduce(
            (acc, cur) => ({ ...acc, [cur.uid]: cur }),
            {}
          );
          if (debug) console.log(obj);
          setTips(obj);
        }
      })
      .catch((error) => {
        addError(error);
      });
    loadCategories()
      .then((response) => {
        if (response.data.error) addError(response.data.error);
        if (response.data.data) {
          let obj = response.data.data.reduce(
            (acc, cur) => ({ ...acc, [cur.uid]: cur }),
            {}
          );
          if (debug) console.log(obj);
          setCategories(obj);
        }
      })
      .catch((error) => {
        addError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="modal-content">
      <div id="modal-online-tool-header" className="modal-header">
        <div className="flex-1">
          <h2
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (bagOpen) {
                setShowBag(false);
                if (currentCat > 0) {
                  setTimeout(() => {
                    setCat(0);
                  }, 500);
                }
              } else {
                if (currentCat > 0) setCat(0);
              }
            }}
          >
            One Planet Guide
          </h2>
          {(currentCat > 0 || bagOpen) && (
            <React.Fragment>
              {bagOpen && (
                <span
                  className="modal-header__special mobile active"
                  onClick={() => setShowBag(false)}
                >
                  Tipps schließen
                </span>
              )}
              {!bagOpen && (
                <span
                  className={
                    currentCat > 0
                      ? "modal-header__special mobile active"
                      : "modal-header__special mobile"
                  }
                  onClick={() => resetCat()}
                >
                  <span className="chev-left"></span>Zurück zur Themenübersicht
                </span>
              )}
            </React.Fragment>
          )}
        </div>
        <div className="modal-header__operational">
          {bagOpen && (
            <span
              className="modal-header__special active"
              onClick={() => setShowBag(false)}
            >
              Tipps schließen
            </span>
          )}
          {!bagOpen && (
            <React.Fragment>
              <span
                className={
                  currentCat > 0
                    ? "modal-header__special active"
                    : "modal-header__special"
                }
                onClick={() => resetCat()}
              >
                Zur Themenübersicht
              </span>
              <button
                type="button"
                className="btn btn--close"
                aria-label="Close"
                onClick={() => {
                  setShow(true);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
      <div
        id="confirmation-leave-guide"
        className={show ? "modal modal--2 modal--active" : "modal modal--2"}
        role="dialog"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h3>
                Sind Sie sich sicher, dass Sie den One Planet Guide verlassen
                wollen? Ihre Reisetipps gehen somit verloren.
              </h3>
            </div>
            {isDesktop && (
              <div className="modal-body text-center">
                <button
                  type="button"
                  className="btn btn--inline btn--rounded"
                  data-dismiss="modal"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Ja, zurück zur Webseite
                </button>
                <button
                  type="button"
                  className="btn btn--inline btn--rounded"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Oh nein, im One Planet Guide bleiben
                </button>
              </div>
            )}
            {!isDesktop && (
              <div className="modal-body text-center">
                <button
                  type="button"
                  className="btn btn--inline btn--rounded"
                  data-dismiss="modal"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Guide verlassen
                </button>
                <button
                  type="button"
                  className="btn btn--inline btn--rounded"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Im Guide bleiben
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal-body"
        ref={componentRef}
        onScroll={() => scrollListener()}
      >
        {bagOpen && (
          <BagContent
            showBag={showBag}
            setShowBag={setShowBag}
            closeBag={closeBag}
            mores={mores}
            tips={tips}
            savedTips={savedTips}
            savedMores={savedMores}
            setBag={setBag}
            winWidth={winWidth}
          />
        )}

        {curNumber === 99 && winWidth <= 575 && (
          <div
            className={bagAnimation ? "top-bag shake" : "top-bag"}
            style={{ bottom: footerSafety + "px" }}
            onClick={() => {
              if (bagOpen) {
                setShowBag(false);
              } else {
                openBag();
              }
            }}
          >
            <img src={suitcaseOutlined.default} alt="suitcase" />
            <div data-js="modal-footer-suitcase">{bagNumber}</div>
          </div>
        )}

        <Fade
          in={compIn}
          onEnter={() => handleResize()}
          onExited={() => setCurrentCat(nextNum)}
        >
          <div className={bagOpen ? "d-none" : "inbetween-container"}>
            {netErrors.length > 0 && (
              <div className="errorBlock">
                {netErrors.map((error, index) => (
                  <p key={index}>{error}</p>
                ))}
              </div>
            )}

            {currentCat === 0 && winWidth <= 575 && (
              <CatMapMobile setCurrentCat={setCat} visited={visited} />
            )}
            {currentCat === 0 && winWidth > 575 && winWidth <= 991 && (
              <CatMapTablet setCurrentCat={setCat} visited={visited} />
            )}
            {currentCat === 0 && winWidth > 991 && (
              <CatMapDesktop setCurrentCat={setCat} visited={visited} />
            )}
            {currentCat !== 0 && (
              <QuestRoute
                curNumber={curNumber}
                setCurNumber={setCurNumber}
                winWidth={winWidth}
                categories={categories}
                category={currentCat}
                setBag={setBag}
                mores={mores}
                tips={tips}
                savedTips={savedTips}
                savedMores={savedMores}
                handleResize={handleResize}
                setCurrentCat={setCat}
                routeFinished={routeFinished}
                pushDataLayer={pushDataLayer}
                allAnswers={allAnswers}
                setAllAnswers={setAllAnswers}
                routeIn={routeIn}
                setRouteIn={setRouteIn}
              />
            )}
          </div>
        </Fade>

        <div
          id="modal-online-tool-footer"
          className={
            currentCat > 0 || bagOpen ? "modal-footer cat" : "modal-footer"
          }
        >
          <div className="modal-footer__logo">
            <img
              src={bottomLogo.default}
              alt="bottom logo"
              className="brot-fuer-die-welt-logo"
            />
            <img
              src={tourismWatch.default}
              alt="tourism-watch"
              className="tourism-watch-logo"
            />
          </div>

          {curNumber === 99 && (
            <button
              className={showBckTop ? "backtotop show" : "backtotop"}
              onClick={() => scrollToTop()}
            >
              <span className="backtotop-icon"></span>
            </button>
          )}

          {bottomBagVisible && (
            <div
              className={
                bagAnimation
                  ? "modal-footer__right-content shake"
                  : "modal-footer__right-content"
              }
              onClick={() => {
                if (bagOpen) {
                  scrollToTop();
                  // setShowBag(false);
                } else {
                  openBag();
                }
              }}
            >
              <img src={suitcase.default} alt="suitcase" />
              <div data-js="modal-footer-suitcase">{bagNumber}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalView;
