import React, { useState, useEffect } from "react";
import { Fade } from "@material-ui/core";

import QuestIntro from "./QuestIntro";
import QuestItem from "./QuestItem";
import QuestResult from "./QuestResult";

function QuestRoute({
  curNumber,
  setCurNumber,
  winWidth,
  categories,
  category,
  setBag,
  mores,
  tips,
  savedTips,
  savedMores,
  handleResize,
  routeFinished,
  pushDataLayer,
  setCurrentCat,
  allAnswers,
  setAllAnswers,
  routeIn,
  setRouteIn,
}) {
  const [route, setRoute] = useState({});
  const [answers, setAnswers] = useState({});
  const [preVal, setPreVal] = useState();

  const preSelect = (val) => {
    setRouteIn(false);
    setPreVal(val);
  };

  const select = (val) => {
    if (val) {
      let newAnswers = answers;
      newAnswers[curNumber] = val;
      setAnswers(newAnswers);
    }
    if (route["questions"][curNumber + 1]) {
      setCurNumber(curNumber + 1);
    } else {
      if (curNumber === 98) {
        setCurNumber(99);
      } else {
        setCurNumber(98);
      }
    }
    setRouteIn(true);
  };

  useEffect(() => {
    if (
      categories[category].gtmIdentifier &&
      categories[category].gtmIdentifier !== ""
    ) {
      pushDataLayer(categories[category].gtmIdentifier);
    }
    if (
      allAnswers[category] !== undefined &&
      allAnswers[category][0] !== undefined
    ) {
      setCurNumber(99);
    } else {
      setCurNumber(-1);
    }
    setRoute(categories[category]);
    handleResize();
    setRouteIn(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  if (route === {}) return null;

  return (
    <div className="flex-in">
      <Fade in={routeIn} onExited={() => select(preVal)}>
        <div className="flex-in">
          {/* intro section */}
          {curNumber === -1 && (
            <QuestIntro
              category={category}
              data={route}
              icon={route["icon"]}
              nextNum={preSelect}
              handleResize={handleResize}
            />
          )}
          {/* question */}
          {curNumber > -1 && curNumber < 98 && (
            <div className="guide-category-quiz">
              <QuestItem
                winWidth={winWidth}
                category={category}
                titleClr={route.titleClr}
                data={route["questions"][curNumber]}
                icon={route["icon"]}
                number={curNumber}
                select={select}
                handleResize={handleResize}
              />
            </div>
          )}
          {curNumber === 98 && (
            <div className="guide-category-quiz">
              <QuestItem
                winWidth={winWidth}
                category={category}
                titleClr={route.titleClr}
                data={route["questions"][curNumber]}
                icon={route["icon"]}
                number={curNumber}
                select={preSelect}
                handleResize={handleResize}
              />
            </div>
          )}
          {/* result */}
          {curNumber === 99 && (
            <div className="guide-result">
              <QuestResult
                data={route["result"]}
                icon={route["icon"]}
                questions={route["questions"]}
                titleClr={route.titleClr}
                answers={answers}
                setAnswers={setAnswers}
                setBag={setBag}
                mores={mores}
                tips={tips}
                savedTips={savedTips}
                winWidth={winWidth}
                savedMores={savedMores}
                routeFinished={routeFinished}
                setCurrentCat={setCurrentCat}
                category={category}
                allAnswers={allAnswers}
                setAllAnswers={setAllAnswers}
              />
            </div>
          )}
        </div>
      </Fade>
    </div>
  );
}

export default QuestRoute;
