import React, { useEffect, useState } from "react";

function Tip({ id, cols, setBag, tips, savedTips }) {
  const [tip, setTip] = useState();
  const [bagged, setBagged] = useState(false);

  const checkBag = () => {
    if (savedTips.indexOf(id) > -1) {
      setBagged(true);
    } else {
      setBagged(false);
    }
  };

  useEffect(() => {
    setTip(tips[id]);
    checkBag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, savedTips]);

  if (Object.keys(tips).length === 0) return null;
  if (!tip) return null;
  if (!cols) return null;

  return (
    <div className={"carousel-cell size-" + 12 / cols}>
      <div className="guide-result-tip">
        <div className="title">
          <h3>{tip.title}</h3>
        </div>
        <p>{tip.text}</p>

        {!bagged && (
          <button
            type="button"
            className="btn btn--rounded btn--white bordered"
            data-js="add-result-tip"
            onClick={() => {
              setBag(id, "tip", true);
              checkBag();
            }}
          >
            Tipp einpacken
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22">
              <path d="M12.75 22a.739.739 0 01-.75-.728v-.727H6v.727a.739.739 0 01-.75.728h-1.5a.739.739 0 01-.75-.728v-.727h-.75A2.216 2.216 0 010 18.364V8.182A2.216 2.216 0 012.25 6H5V2.25A2.25 2.25 0 017.25 0h4.5A2.25 2.25 0 0114 2.25V6h1.75A2.216 2.216 0 0118 8.182v10.182a2.216 2.216 0 01-2.25 2.182H15v.727a.739.739 0 01-.75.727zm-1-16V2.25h-4.5V6z" />
            </svg>
          </button>
        )}
        {bagged && (
          <button
            type="button"
            className="btn btn--rounded btn--orange bordered shake"
            data-js="add-result-tip"
            onClick={() => {
              setBag(id, "tip", false);
              checkBag();
            }}
          >
            Tipp auspacken
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22">
              <path d="M12.75 22a.739.739 0 01-.75-.728v-.727H6v.727a.739.739 0 01-.75.728h-1.5a.739.739 0 01-.75-.728v-.727h-.75A2.216 2.216 0 010 18.364V8.182A2.216 2.216 0 012.25 6H5V2.25A2.25 2.25 0 017.25 0h4.5A2.25 2.25 0 0114 2.25V6h1.75A2.216 2.216 0 0118 8.182v10.182a2.216 2.216 0 01-2.25 2.182H15v.727a.739.739 0 01-.75.727zm-1-16V2.25h-4.5V6z" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}

export default Tip;
