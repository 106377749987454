import React, { useEffect, useState } from "react";
import { Fade } from "@material-ui/core";
const baseURL = process.env.REACT_APP_API_URL;

function QuestIntro({ data, nextNum, icon, handleResize, category }) {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data || !icon) return null;

  return (
    <div className="guide-category-info">
      <Fade
        in={fadeIn}
        onEnter={() => handleResize()}
        onExited={() => nextNum()}
      >
        <div className="container">
          <div className="guide-category-info__item">
            <div className="row mobile-reversed align-items-center align-items-lg-start">
              <div className="col-12 col-md-8">
                <div className="guide-category-info__item-titles">
                  <div className={"title title-clr-" + category}>
                    <h1>{data.titleClr}</h1>
                  </div>
                  {data.title !== "" && (
                    <div className="title title">
                      <h1>{data.title}</h1>
                    </div>
                  )}
                </div>
                <p>{data.text}</p>
              </div>
              <div className="col-12 col-md-4 guide-category-info__item-image">
                <img
                  src={baseURL + icon.url}
                  alt={icon.alternative}
                  title={icon.title}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                {data.buttonText === "" && (
                  <button
                    type="button"
                    className="btn btn--rounded btn--orange btn--center"
                    data-js="to-quiz"
                    onClick={() => setFadeIn(false)}
                  >
                    Weiter
                  </button>
                )}
                {data.buttonText !== "" && (
                  <button
                    type="button"
                    className="btn btn--rounded btn--orange btn--center"
                    data-js="to-quiz"
                    onClick={() => setFadeIn(false)}
                  >
                    {data.buttonText}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default QuestIntro;
