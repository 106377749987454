import React, { useEffect, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";

import QuizTransition from "./animations/QuizTransition";
import ItemVisual from "./ItemVisual";

function QuestItem({
  titleClr,
  winWidth,
  category,
  data,
  number,
  select,
  icon,
}) {
  const [transitionIn, setTransitionIn] = useState(false);
  const [selectNo, setSelectNo] = useState(false);
  const [touchedIndex, setTouchedIndex] = useState(-1);

  const pushDataLayer = (identifier) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "answered",
      answer: identifier,
    });
  };

  const selectAnswer = (value) => {
    setSelectNo(value);
    setTransitionIn(false);
  };

  useEffect(() => {
    setTransitionIn(true);
    setTouchedIndex(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (number === undefined) return null;

  if (number === 98) {
    setTimeout(() => {
      setTransitionIn(false);
    }, 1000);

    return (
      <div className="quiz-item">
        <QuizTransition transitionIn={transitionIn} exited={() => select()}>
          <div className="container">
            <ItemVisual winWidth={winWidth} number={number} icon={icon} />
            <div className="quiz-item__content">
              <div className="row">
                <div className="col-12">
                  <div className="title">
                    <h3>Ergebnisse werden gesammelt ...</h3>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-8 quiz-item__content-title">
                  <div className="title"></div>
                </div>
                <div className="col-12 col-md-6 col-lg-4"></div>
              </div>
            </div>
          </div>
        </QuizTransition>
      </div>
    );
  }

  if (winWidth <= 575) {
  }

  return (
    <div className="quiz-item">
      <QuizTransition
        transitionIn={transitionIn}
        exited={() => select(selectNo)}
      >
        <div className="container">
          <ItemVisual winWidth={winWidth} number={number} icon={icon} />
          <div className="quiz-item__content">
            <div className="row">
              <div className="col-12">
                <div className={"title title-clr-" + category}>
                  <h3>{titleClr}</h3>
                </div>
                <div className="title question-text">
                  <h3>{data.title}</h3>
                </div>
              </div>
            </div>

            {winWidth > 575 && (
              <div className="row">
                <div className="col-12 col-md-6 col-xl-8 "></div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div className="question">
                    <legend>Wählen Sie eine Antwort aus</legend>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-12 col-md-6 col-xl-8 quiz-item__content-title">
                <div className="title">
                  <h2>{data.question}</h2>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4">
                <div
                  className={
                    isMobile || isTablet ? "question touch" : "question mouse"
                  }
                >
                  {data.answers.map((item, index) => (
                    <label
                      key={index}
                      className={touchedIndex === index ? "active" : ""}
                    >
                      <input
                        type="radio"
                        name={"question-" + category}
                        id={"question-" + category + "-" + index}
                        value={index + 1}
                        onClick={() => {
                          if (item.gtmIdentifier && item.gtmIdentifier !== "") {
                            pushDataLayer(item.gtmIdentifier);
                          }
                          setTouchedIndex(index);
                          selectAnswer(item.value);
                        }}
                      />
                      {item.value}
                    </label>
                  ))}
                </div>
              </div>
            </div>

            {winWidth <= 575 && (
              <div className="row">
                <div className="col-12 col-md-6 col-xl-8 "></div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div className="question">
                    <legend>Wählen Sie eine Antwort aus</legend>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </QuizTransition>
    </div>
  );
}

export default QuestItem;
