import React from "react";
import { CSSTransition } from "react-transition-group";

function QuizTransition({ transitionIn, exited, children }) {
  return (
    <CSSTransition
      in={transitionIn}
      timeout={1500}
      onExited={exited}
      className="quiz-transition"
      mountOnEnter={true}
      appear={true}
    >
      <div>{children}</div>
    </CSSTransition>
  );
}

export default QuizTransition;
