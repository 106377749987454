import React from "react";

function ItemVisual({ winWidth, number, icon }) {
  const baseURL = process.env.REACT_APP_API_URL;

  if (winWidth <= 575) {
    return (
      <div className="quiz-item__visual">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="253"
          height="70"
          viewBox="0 0 253 70"
        >
          <defs>
            <clipPath id="fsura">
              <path d="M18 0h7v15h-7z" />
            </clipPath>
            <clipPath id="fsurb">
              <path d="M32 20h13v25H32z" />
            </clipPath>
          </defs>
          <path
            fill="none"
            stroke="#80607a"
            strokeDasharray="3 3"
            strokeMiterlimit="20"
            d="M33 59.5h34.5"
          />
          <path
            fill="none"
            stroke="#80607a"
            strokeDasharray="3 3"
            strokeMiterlimit="20"
            d="M88 59.5h34.5"
          />
          <path
            fill="none"
            stroke="#80607a"
            strokeDasharray="3 3"
            strokeMiterlimit="20"
            d="M145 59.5h34.5"
          />
          <path
            fill="none"
            stroke="#80607a"
            strokeDasharray="3 3"
            strokeMiterlimit="20"
            d="M200 59.5h34.5"
          />
          <g>
            <path
              fill="#eb680b"
              d="M21.486 65.738C17.702 60.389 17 59.84 17 57.875 17 55.183 19.239 53 22 53s5 2.183 5 4.875c0 1.966-.702 2.514-4.486 7.863a.634.634 0 0 1-1.028 0z"
            />
            <g transform="translate(0 0)">
              <image
                href={baseURL + icon.url}
                alt={icon.alternative}
                title={icon.title}
                height="45"
                width="45"
                className={number === 0 ? "active" : ""}
              />
            </g>
          </g>
          <g>
            <path
              className={number >= 1 ? "path-active" : ""}
              fill="#f7c29d"
              d="M76.486 65.738C72.702 60.389 72 59.84 72 57.875 72 55.183 74.239 53 77 53s5 2.183 5 4.875c0 1.966-.702 2.514-4.486 7.863a.634.634 0 0 1-1.028 0z"
            />
            <g transform="translate(57.5 0)">
              <image
                href={baseURL + icon.url}
                alt={icon.alternative}
                title={icon.title}
                height="45"
                width="45"
                className={number === 1 ? "active" : ""}
              />
            </g>
          </g>
          <g>
            <path
              className={number >= 2 ? "path-active" : ""}
              fill="#f7c29d"
              d="M132.486 65.738C128.702 60.389 128 59.84 128 57.875c0-2.692 2.239-4.875 5-4.875s5 2.183 5 4.875c0 1.966-.702 2.514-4.486 7.863a.634.634 0 0 1-1.028 0z"
            />
            <g transform="translate(115 0)">
              <image
                href={baseURL + icon.url}
                alt={icon.alternative}
                title={icon.title}
                height="45"
                width="45"
                className={number === 2 ? "active" : ""}
              />
            </g>
          </g>
          <g>
            <path
              className={number >= 3 ? "path-active" : ""}
              fill="#f7c29d"
              d="M188.486 65.738C184.702 60.389 184 59.84 184 57.875c0-2.692 2.239-4.875 5-4.875s5 2.183 5 4.875c0 1.966-.702 2.514-4.486 7.863a.634.634 0 0 1-1.028 0z"
            />
            <g transform="translate(172.5 0)">
              <image
                href={baseURL + icon.url}
                alt={icon.alternative}
                title={icon.title}
                height="45"
                width="45"
                className={number === 3 ? "active" : ""}
              />
            </g>
          </g>
          <g>
            <path
              className={number >= 4 ? "path-active" : ""}
              fill="#f7c29d"
              d="M249.132 55.508c-1.275 0-2.33-.883-3.933-.883-.594 0-1.127.111-1.62.305.068-.19.097-.395.085-.598-.042-.722-.597-1.301-1.275-1.33-.762-.034-1.389.615-1.389 1.42 0 .483.226.91.571 1.166v9.803c0 .336.256.609.572.609h.38c.316 0 .572-.273.572-.61v-2.396c.674-.307 1.514-.562 2.725-.562 1.276 0 2.33.883 3.934.883 1.146 0 2.063-.414 2.916-1.037a.828.828 0 0 0 .33-.67v-6.172c0-.594-.578-.987-1.083-.737-.818.405-1.82.81-2.785.81z"
            />
            <g transform="translate(230 0)">
              <image
                href={baseURL + icon.url}
                alt={icon.alternative}
                title={icon.title}
                height="45"
                width="45"
                className={number === 4 ? "active" : ""}
              />
            </g>
          </g>
        </svg>
      </div>
    );
  }
  return (
    <div className="quiz-item__visual">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1039"
        height="173"
        viewBox="0 0 1039 173"
      >
        <defs>
          <clipPath id="shh5a">
            <path d="M34 37h14v29H34z" />
          </clipPath>
          <clipPath id="shh5b">
            <path d="M62 76h26v48H62z" />
          </clipPath>
        </defs>
        <g>
          <path
            fill="none"
            stroke="#80607a"
            strokeDasharray="4 4"
            strokeMiterlimit="20"
            d="M70.003 139v0s-6-16 15-23.5 46.82 2.5 65.41 2.5c18.59 0 44.81 25.711 69.95 7.856 12.527-8.897 32.116-15.22 49.474-22.73 17.477-7.56 32.694-16.324 36.166-30.125 6.922-27.51 38.789-61 49.145-61 10.355 0 31.488-5.783 44.855 9.041 13.368 14.825 16.994 40.838 53.362 32.959 36.367-7.88 39.936-3 52.787 12 12.851 15 49.931 58.71 71.186 59.855 21.256 1.145 59.508 14.645 90.165-5.355 30.657-20 56.831-65 112.5-66.5 55.67-1.5 119.312 70 161.953 55 42.641-15 16.907-57.5 63.047-57.5"
          />
          <g>
            <path
              fill="#eb680b"
              d="M61.302 172.375C52.612 159.62 51 158.312 51 153.625 51 147.205 56.14 142 62.481 142c6.342 0 11.482 5.205 11.482 11.625 0 4.687-1.613 5.996-10.302 18.75-.57.834-1.79.833-2.36 0z"
            />
            <g transform="translate(10 50)">
              <image
                href={baseURL + icon.url}
                alt={icon.alternative}
                title={icon.title}
                height="90"
                width="90"
                className={number === 0 ? "active" : ""}
              />
            </g>

            <text
              xmlns="http://www.w3.org/2000/svg"
              fill="#fff"
              fontFamily="CopernicusBold"
              fontSize="14"
              transform="translate(58 160)"
            >
              1
            </text>
          </g>
          <g>
            <path
              fill="#eaeff9"
              d="M299 42h27v34h-27z"
              className="turn-white"
            />
            <path
              className={number >= 1 ? "path-active" : ""}
              fill="#f7c29d"
              d="M305.302 68.375C296.612 55.62 295 54.312 295 49.625 295 43.205 300.14 38 306.481 38c6.342 0 11.482 5.205 11.482 11.625 0 4.687-1.613 5.996-10.302 18.75-.57.834-1.79.833-2.36 0z"
            />
            <g transform="translate(303 55)">
              <image
                href={baseURL + icon.url}
                alt={icon.alternative}
                title={icon.title}
                height="90"
                width="90"
                className={number === 1 ? "active" : ""}
              />
            </g>

            <text
              xmlns="http://www.w3.org/2000/svg"
              fill="#fff"
              fontFamily="CopernicusBold"
              fontSize="14"
              transform="translate(302 55)"
            >
              2
            </text>
          </g>
          <g>
            <path
              fill="#ffffff"
              d="M536 93h34v40h-34z"
              transform="translate(0 10)"
            />
            <path
              className={number >= 2 ? "path-active" : ""}
              fill="#f7c29d"
              transform="translate(5 0)"
              d="M552.302 120.375C543.612 107.62 542 106.312 542 101.625 542 95.205 547.14 90 553.481 90c6.342 0 11.482 5.205 11.482 11.625 0 4.687-1.613 5.996-10.302 18.75-.57.834-1.79.833-2.36 0z"
            />
            <g transform="translate(530 0)">
              <image
                href={baseURL + icon.url}
                alt={icon.alternative}
                title={icon.title}
                height="90"
                width="90"
                className={number === 2 ? "active" : ""}
              />
            </g>

            <text
              xmlns="http://www.w3.org/2000/svg"
              fill="#fff"
              fontFamily="CopernicusBold"
              fontSize="14"
              transform="translate(554 107)"
            >
              3
            </text>
          </g>
          <g>
            <path
              fill="#eaeff9"
              d="M771 41h26v23h-26z"
              className="turn-white"
            />
            <path
              className={number >= 3 ? "path-active" : ""}
              fill="#f7c29d"
              d="M782.302 59.375C773.612 46.62 772 45.312 772 40.625 772 34.205 777.14 29 783.481 29c6.342 0 11.482 5.205 11.482 11.625 0 4.687-1.613 5.996-10.302 18.75-.57.834-1.79.833-2.36 0z"
            />
            <g transform="translate(740 56)">
              <image
                href={baseURL + icon.url}
                alt={icon.alternative}
                title={icon.title}
                height="90"
                width="90"
                className={number === 3 ? "active" : ""}
              />
            </g>

            <text
              xmlns="http://www.w3.org/2000/svg"
              fill="#fff"
              fontFamily="CopernicusBold"
              fontSize="14"
              transform="translate(779 46)"
            >
              4
            </text>
          </g>
          <g>
            <path
              className={number >= 4 ? "path-active" : ""}
              fill="#f7c29d"
              d="M1029.331 20.981c-3.19 0-5.824-2.106-9.834-2.106-1.486 0-2.816.265-4.05.727a3.44 3.44 0 0 0 .213-1.426c-.105-1.722-1.493-3.103-3.188-3.173-1.904-.079-3.472 1.468-3.472 3.388 0 1.152.565 2.169 1.429 2.781v23.375c0 .802.64 1.453 1.428 1.453h.953c.789 0 1.428-.65 1.428-1.453V38.83c1.685-.73 3.785-1.34 6.812-1.34 3.19 0 5.824 2.107 9.834 2.107 2.867 0 5.159-.987 7.292-2.474.517-.36.824-.959.824-1.596V20.81c0-1.416-1.445-2.353-2.707-1.757-2.044.966-4.551 1.929-6.962 1.929z"
            />
            <g transform="translate(950 45)">
              <image
                href={baseURL + icon.url}
                alt={icon.alternative}
                title={icon.title}
                height="90"
                width="90"
                className={number === 4 ? "active" : ""}
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default ItemVisual;
