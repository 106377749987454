import React, { useState, useEffect } from "react";
import { Collapse } from "@material-ui/core";
import { isMobile, isTablet, isDesktop } from "react-device-detect";

import Rating from "./Rating";

const svgDownWhite = require("../assets/icons/arrow-down-white.svg");

function ResultReview({
  activeIndex,
  setActiveIndex,
  index,
  data,
  answer,
  winWidth,
}) {
  const [open, setOpen] = useState(false);
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    if (isDesktop) {
      activeIndex !== index ? setOpen(false) : setClosed(false);
    }
    if (isMobile || isTablet) {
      if (activeIndex === index) {
        setOpen(true);
        setClosed(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  useEffect(() => {
    activeIndex === index ? setOpen(true) : setClosed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (winWidth <= 991)
    return (
      <div
        className={open ? "guide-result-review open" : "guide-result-review"}
        onClick={() => {
          setActiveIndex(index);
        }}
      >
        <div className="row">
          <div className="col-12">
            <div className="title">
              <h2>{data.question}</h2>
            </div>
          </div>

          {data.ratings.length > 0 && (
            <div className="col-12">
              <div className="guide-result__ratings">
                {data.ratings.map((rating, index) => (
                  <div className="guide-result__rating" key={index}>
                    <span className="text">{rating.text}</span>
                    <Rating fill={rating.rating} />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div
            className={open ? "col-12 right-side open" : "col-12 right-side"}
          >
            <Collapse in={open} onExited={() => setClosed(true)}>
              <div>
                <div className="guide-result-review__description-quote"></div>
              </div>
            </Collapse>
            <Collapse in={closed} onExited={() => setOpen(true)}>
              <div className="btn-col">
                <div className="btn btn--open-info" data-js="open-result-desc">
                  <img src={svgDownWhite.default} alt="arrow down white" />
                </div>
              </div>
            </Collapse>
          </div>
          <div className="col-12 ">
            <Collapse in={open}>
              <div>
                <div className="guide-result-review__description-content">
                  <p>
                    <strong>{data.review.title}</strong>
                    <br />
                    {data.review.text}
                  </p>
                </div>
                <div className="guide-result-review__description-quote">
                  <span className="guide-result-review__description-author">
                    Ihre Antwort war:
                  </span>
                  <blockquote>{answer}</blockquote>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    );

  return (
    <div
      className={open ? "guide-result-review open" : "guide-result-review"}
      onClick={() => setActiveIndex(index)}
    >
      <div className="row">
        <div className="col-12 col-lg">
          <div className="title">
            <h2>{data.question}</h2>
          </div>

          {data.ratings.length > 0 && (
            <div className="guide-result__ratings">
              {data.ratings.map((rating, index) => (
                <div className="guide-result__rating" key={index}>
                  <span>{rating.text}</span>
                  <Rating fill={rating.rating} />
                </div>
              ))}
            </div>
          )}

          <Collapse in={open}>
            <div>
              <div className="guide-result-review__description-content">
                <p>
                  <strong>{data.review.title}</strong>
                  <br />
                  {data.review.text}
                </p>
              </div>
            </div>
          </Collapse>
        </div>
        <div
          className={
            open
              ? "col-12 col-md-auto col-xl-3 right-side open"
              : "col-12 col-md-auto col-xl-3 right-side"
          }
        >
          <Collapse in={open} onExited={() => setClosed(true)}>
            <div>
              <div className="guide-result-review__description-quote">
                <span className="guide-result-review__description-author">
                  Ihre Antwort war:
                </span>
                <blockquote>{answer}</blockquote>
              </div>
            </div>
          </Collapse>
          <Collapse in={closed} onExited={() => setOpen(true)}>
            <div>
              <div className="btn btn--open-info" data-js="open-result-desc">
                <img src={svgDownWhite.default} alt="arrow down white" />
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
}

export default ResultReview;
