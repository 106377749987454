import React, { useEffect, useState } from "react";

const baseURL = process.env.REACT_APP_API_URL;

function Person({ data, cols = 2 }) {
  const [person, setPerson] = useState();

  useEffect(() => {
    setPerson(data);
  }, [data]);

  if (!person) return null;

  return (
    <div className={"guide-result__story carousel-cell size-" + 12 / cols}>
      <div className="title">
        <h2>{person.category}</h2>
      </div>
      <div className="guide-result__story-header">
        <img src={baseURL + data.img} alt={data.alt} />
        <div className="title">
          <h3>{person.title}</h3>
          <span>{person.subtitle}</span>
        </div>
      </div>
      <div className="guide-result__story-description">
        <p>{person.text}</p>
      </div>
    </div>
  );
}

export default Person;
