import React, { useState, useEffect, createRef } from "react";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import { Collapse, Slide } from "@material-ui/core";
import { saveAs } from "file-saver";
import { EqualHeight, EqualHeightElement } from "react-equal-height";

import Tip from "./Tip";
import More from "./More";

const bagWhite = require("../assets/icons/bag-white.svg");
const facebook = require("../assets/icons/soc-facebook.svg");
const twitter = require("../assets/icons/soc-twitter.svg");
const instagram = require("../assets/icons/soc-instagram.svg");
const download = require("../assets/icons/download.svg");
const tourismWatch = require("../assets/icons/logo-tourism-watch.svg");
const bottomLogo = require("../assets/icons/logo-bfdw.svg");
const broshure = require("../assets/images/118100300_fair-reisen.jpeg");

function BagContent({
  showBag,
  setShowBag,
  mores,
  tips,
  savedTips,
  savedMores,
  closeBag,
  setBag,
  winWidth,
}) {
  const [showInsta, setShowInsta] = useState(false);
  const [tipOpen, setTipOpen] = useState(false);
  const [moreOpen, setMoreOpen] = useState(false);
  const [tipArray, setTipArray] = useState(false);
  const [moreArray, setMoreArray] = useState(false);
  const [modalImg, setModelImg] = useState("");

  const baseURL = process.env.REACT_APP_API_URL;
  const shareURL = encodeURIComponent(window.location);
  const shareWidth = 800;
  const shareHeight = 550;
  const bagRef = createRef();
  const tipRef = createRef();
  const moreRef = createRef();
  const instaRef = createRef();

  console.log(mores);

  const facebookQuote =
    "Sammeln Sie mit dem One Planet Guide von Tourism Watch bei Brot für die Welt inspirierende Tipps für faires Reisen.";
  const twitterQuote =
    "Sammeln Sie mit dem One Planet Guide von @BROT_furdiewelt inspirierende Tipps für faires Reisen.";
  const twitterHashtag = "tourismwatch,oneplanetguide";

  const scrollToTop = () => {
    if (bagRef.current) {
      bagRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const scrollToTipps = () => {
    if (tipRef.current) tipRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToMores = () => {
    if (moreRef.current) moreRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const downloadTips = () => {
    let zip = new JSZip();
    let count = 0;

    savedTips.forEach(function (id) {
      let url = baseURL + tips[id].img;
      let filename = url.replace(/.*\//g, "");

      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          throw err;
        }
        zip.file(filename, data, { binary: true });
        count++;
        if (count === savedTips.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, "One_Planet_Guide.zip");
          });
        }
      });
    });
  };

  const shareImage = (type, title, uid) => {
    let winTop = window.height / 2 - shareWidth / 2;
    let winLeft = window.width / 2 - shareHeight / 2;

    setTimeout(() => {
      if (type === "fb") {
        window.open(
          "https://www.facebook.com/dialog/share?%20app_id=1603843519910518%20&href=" +
            shareURL +
            "?sharepic=" +
            uid +
            "&display=popup" +
            "&quote=" +
            facebookQuote,
          "teilen",
          "top=" +
            winTop +
            ",left=" +
            winLeft +
            ",toolbar=0,status=0,width=" +
            shareWidth +
            ",height=" +
            shareHeight
        );
      }
      if (type === "tw") {
        window.open(
          "https://twitter.com/intent/tweet?url=" +
            shareURL +
            "?sharepic=" +
            uid +
            "&text=" +
            twitterQuote +
            "&hashtags=" +
            twitterHashtag,
          "teilen",
          "top=" +
            winTop +
            ",left=" +
            winLeft +
            ",toolbar=0,status=0,width=" +
            shareWidth +
            ",height=" +
            shareHeight
        );
      }
    }, 500);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showInsta &&
        instaRef.current &&
        !instaRef.current.contains(e.target)
      ) {
        setShowInsta(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showInsta]);

  useEffect(() => {
    let array = [];
    for (let key in tips) {
      array.push(key);
    }
    setTipArray(array);

    let moreArray = [];
    for (let key in mores) {
      moreArray.push(key);
    }
    setMoreArray(moreArray);
    console.log(moreArray);
  }, [tips, mores, savedTips, savedMores]);

  return (
    <Slide
      in={showBag}
      direction="up"
      onEntered={() => {
        scrollToTop();
      }}
      onExited={() => closeBag()}
    >
      <div className="guide-suitcase" ref={bagRef}>
        <div
          id="confirmation-download-icons"
          className={
            showInsta ? "modal modal--2 modal--active" : "modal modal--2"
          }
        >
          <div className="modal-dialog modal-lg" ref={instaRef}>
            <div className="modal-content">
              <div className="modal-header">
                <h3>
                  Lade Deinen gewählten Tipp herunter und teile ihn direkt in
                  deinem Account oder reposte Deinen Tipp direkt von unserem
                  Tourism Watch Instagram-Kanal.
                </h3>
              </div>
              <div className="modal-body text-center">
                <a
                  href={baseURL + modalImg}
                  title="Download"
                  target="_blank"
                  rel="noreferrer"
                  download
                  className="btn btn--inline btn--rounded"
                  onClick={() => {
                    setShowInsta(false);
                  }}
                >
                  <img src={download.default} alt="download" />
                </a>
                <a
                  href="https://www.instagram.com/tourismwatchinfo/"
                  target="_blank"
                  rel="noreferrer"
                  title="TourismWatch auf Instagram"
                  className="btn btn--rounded btn--inline btn--orange"
                >
                  <img
                    src={instagram.default}
                    alt="instagram"
                    style={{ height: 20 }}
                  />
                </a>
                {/* <button
                  type="button"
                  className="btn btn--inline btn--rounded btn--white"
                  onClick={() => {
                    setShowInsta(false);
                  }}
                >
                  Abbrechen
                </button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="guide-suitcase__inner">
          <div className="container">
            <div className="row">
              <div className="guide-suitcase__header col-12">
                <div className="title">
                  <h1>Tipps für meine nächste Reise</h1>
                </div>
                <p>
                  Viel Spaß beim Ausprobieren und Teilen!
                  <br />
                  Wir wünschen eine gute Reise.
                </p>
                <div className="guide-suitcase__header-result notification notification--left">
                  <img src={bagWhite.default} alt="bag-white" />
                  <span>
                    Sie haben{" "}
                    <span data-js="suitcase-result-number">
                      {savedTips.length}
                    </span>{" "}
                    Tipps gesammelt
                  </span>
                </div>
              </div>

              {savedTips.length === 0 && (
                <div className="col-12">
                  <p>- Keine Einträge vorhanden -</p>
                </div>
              )}
            </div>

            <EqualHeight>
              <div className="row">
                <Collapse in={savedTips.length > 0}>
                  {savedTips.length > 0 &&
                    savedTips.map((id, index) => (
                      <div className="col-12 col-sm-6 col-lg-4" key={index}>
                        <div className="suitcase-item">
                          <EqualHeightElement
                            name="scItem"
                            disable={winWidth <= 575 ? true : false}
                          >
                            <div className="suitcase-item__top">
                              <div>{tips[id].text}</div>
                              <img src={bagWhite.default} alt="bag orange" />
                            </div>
                          </EqualHeightElement>

                          <div className="suitcase-item__main">
                            <img
                              src={baseURL + tips[id].img}
                              alt={tips[id].alt}
                            />
                          </div>
                          <div
                            className="suitcase-item__social"
                            data-js="suitcase-item-social"
                          >
                            <div
                              onClick={() => {
                                shareImage("fb", tips[id].title, tips[id].uid);
                              }}
                            >
                              <img src={facebook.default} alt="facebook" />
                            </div>
                            <div
                              onClick={() => {
                                shareImage("tw", tips[id].title, tips[id].uid);
                              }}
                            >
                              <img src={twitter.default} alt="twitter" />
                            </div>
                            <div
                              title="Instagram"
                              onClick={() => {
                                setModelImg(tips[id].img);
                                setShowInsta(true);
                              }}
                            >
                              <img src={instagram.default} alt="instagram" />
                            </div>
                            <a
                              href={baseURL + tips[id].img}
                              title="Download"
                              target="_blank"
                              rel="noreferrer"
                              download
                            >
                              <img src={download.default} alt="download" />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                </Collapse>
              </div>
            </EqualHeight>

            <div className="guide-suitcase__last text-center">
              {savedTips.length > 0 && winWidth > 991 && (
                <button
                  type="button"
                  className="btn btn--rounded btn--white btn--center"
                  onClick={() => downloadTips()}
                >
                  Meine Tipps herunterladen
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22">
                    <path d="M12.75 22a.739.739 0 01-.75-.728v-.727H6v.727a.739.739 0 01-.75.728h-1.5a.739.739 0 01-.75-.728v-.727h-.75A2.216 2.216 0 010 18.364V8.182A2.216 2.216 0 012.25 6H5V2.25A2.25 2.25 0 017.25 0h4.5A2.25 2.25 0 0114 2.25V6h1.75A2.216 2.216 0 0118 8.182v10.182a2.216 2.216 0 01-2.25 2.182H15v.727a.739.739 0 01-.75.727zm-1-16V2.25h-4.5V6z" />
                  </svg>
                </button>
              )}

              {!tipOpen && (
                <div
                  className="underlined-link"
                  onClick={() => {
                    setTipOpen(!tipOpen);
                  }}
                >
                  Alle Tipps in der Übersicht
                </div>
              )}

              {tipOpen && (
                <div
                  className="underlined-link"
                  onClick={() => {
                    setTipOpen(!tipOpen);
                  }}
                >
                  Übersicht schließen
                </div>
              )}
              <div ref={tipRef}>
                <Collapse
                  in={tipOpen}
                  onEntered={() => {
                    scrollToTipps();
                  }}
                >
                  <div className="guide-result__tips white">
                    <div className="main-carousel row">
                      {tipArray &&
                        tipArray.map((id, index) => (
                          <Tip
                            id={id}
                            key={index}
                            cols={3}
                            setBag={setBag}
                            tips={tips}
                            savedTips={savedTips}
                          />
                        ))}
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>

          {/* <!-- Action boxes --> */}
          <div className="guide-suitcase__action-boxes">
            <div className="container">
              <div className="title text-center">
                <h1>Meine Leseliste</h1>
              </div>

              {savedMores.length === 0 && (
                <div className="col-12">
                  <p>- Keine Einträge vorhanden -</p>
                </div>
              )}

              {savedMores.length > 0 &&
                savedMores.map((id, index) => (
                  <div
                    className="guide-action-box guide-action-box--2 row"
                    key={index}
                  >
                    <div className="col-12 col-md-4 col-lg-3">
                      <a href={mores[id].url} target="_blank" rel="noreferrer">
                        <figure>
                          <img
                            src={baseURL + mores[id].img}
                            alt={mores[id].alt}
                          />
                        </figure>
                      </a>
                    </div>
                    <div className="col-12 col-md-8 col-lg-9">
                      {/* <strong>{mores[id].category}</strong> */}
                      <h2>{mores[id].title}</h2>
                      <p>{mores[id].text}</p>
                      <p>
                        <a
                          className="more-link"
                          href={mores[id].url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {mores[id].link_text}
                        </a>
                      </p>
                    </div>
                  </div>
                ))}

              <div className="guide-suitcase__last more-version">
                {!moreOpen && (
                  <div
                    className="underlined-link text-center more-version"
                    onClick={() => {
                      setMoreOpen(!moreOpen);
                    }}
                  >
                    Alle Artikel zum Weiterlesen anzeigen
                  </div>
                )}

                {moreOpen && (
                  <div
                    className="underlined-link text-center more-version"
                    onClick={() => {
                      setMoreOpen(!moreOpen);
                    }}
                  >
                    Artikelübersicht schließen
                  </div>
                )}

                <div ref={moreRef}>
                  <Collapse
                    in={moreOpen}
                    onEntered={() => {
                      scrollToMores();
                    }}
                  >
                    <div className="guide-result__action-boxes">
                      <div className="main-carousel row">
                        {moreArray &&
                          moreArray.map((id, index) => (
                            <More
                              id={id}
                              key={index}
                              cols={1}
                              setBag={setBag}
                              mores={mores}
                              savedMores={savedMores}
                            />
                          ))}
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                type="button"
                className="btn btn--rounded btn--orange btn--center"
                data-js="close-suitcase"
                onClick={() => {
                  setShowBag(false);
                }}
              >
                Tipps schließen
              </button>
            </div>
          </div>

          <div className="guide-suitcase__newsletter ">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="title text-center">
                    <h2>
                      Bleiben Sie auf dem Laufenden, über Hintergründe und
                      Neuigkeiten!
                    </h2>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <img
                        src={tourismWatch.default}
                        alt="tourism-watch"
                        className="tourism-watch-logo"
                      />
                    </div>

                    <p>
                      Der Tourism-Watch-Infodienst erscheint fünfmal jährlich
                      per E-Mail. Er liefert Hintergrundinformationen aus erster
                      Hand und aktuelle Berichte zum Tourismus in Afrika, Asien
                      und Lateinamerika.
                    </p>
                    <a
                      href="https://www.tourism-watch.de/de/newsletter"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn--rounded btn--orange"
                    >
                      Newsletter abonnieren
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <img
                        src={bottomLogo.default}
                        alt="bottom logo"
                        className="brot-fuer-die-welt-logo"
                      />
                    </div>
                    <p>
                      Alle wichtigen Neuigkeiten, aktuelle Veranstaltungen und
                      Aktionen von Brot für die Welt auf einen Blick in unserem
                      Newsletter. Erfahren Sie außerdem Wissenswertes über
                      unsere Projektarbeit – ganz einfach per E-Mail.
                    </p>
                    <a
                      href="https://www.brot-fuer-die-welt.de/newsletter/"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn--rounded btn--orange"
                    >
                      Newsletter abonnieren
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="image-card">
                    <div className="row">
                      <div className="col-12 col-md-3">
                        <div className="flex-1">
                          <img
                            src={broshure.default}
                            alt="fair-reisen broschuere"
                            className="img-fluid bordered"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-9">
                        <div className="card">
                          <div className="card-header">
                            <h3>Fair Reisen mit Herz und Verstand</h3>
                          </div>
                          <p>
                            Alle Tipps und praktisches Wissen für
                            verantwortungsvolles Reisen im Hosentaschenformat
                            zum Mitnehmen.
                          </p>
                          <a
                            href="https://shop.brot-fuer-die-welt.de/Fair-Reisen-mit-Herz-und-Verstand/118100300"
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn--rounded btn--orange"
                          >
                            Kostenfrei per Post bestellen
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
}

export default BagContent;
