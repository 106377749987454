import React, { useState, useEffect } from "react";
import { Tooltip } from "@material-ui/core";

const starFilled = require("../assets/icons/star-filled.svg");
const star = require("../assets/icons/star.svg");
const starGrey = require("../assets/icons/star-dark.svg");

function Rating({ show = 3, fill = 0, addCl = "" }) {
  const [stars, setStars] = useState([]);
  const [starText, setStarText] = useState("");

  useEffect(() => {
    let newStars = [];
    for (let i = 0; i < show; i++) {
      if (fill < 0) {
        newStars.push(starGrey);
      } else {
        if (i < fill) {
          newStars.push(starFilled);
        } else {
          newStars.push(star);
        }
      }
    }
    setStars(newStars);

    switch (fill) {
      case "-1":
        setStarText("Es gibt keinen Zusammenhang zur Handlung");
        break;
      case "0":
        setStarText("Die Handlung hat eine negative Wirkung");
        break;
      case "1":
        setStarText("Die Handlung hat eine geringe positive Wirkung");
        break;
      case "2":
        setStarText("Die Handlung hat eine mittlere positive Wirkung");
        break;
      case "3":
        setStarText("Die Handlung hat eine große positive Wirkung");
        break;
      default:
        setStarText("Die Handlung hat eine negative Wirkung");
        break;
    }
  }, [show, fill, addCl]);

  return (
    <Tooltip title={starText} arrow>
      <div className={"guide-result__rating-stars " + addCl}>
        {fill < 0 &&
          stars.map((item, index) => (
            <span key={index} className="guide-result__rating-star none" />
          ))}
        {fill >= 0 &&
          stars.map((item, index) => (
            <img
              key={index}
              src={item.default}
              alt="suitcase"
              className="guide-result__rating-star"
            />
          ))}
      </div>
    </Tooltip>
  );
}

export default Rating;
