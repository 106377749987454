import axios from "axios";

export let baseURL = process.env.REACT_APP_API_URL;

export function loadMores() {
  return axios.get(baseURL + "/fairreisen.json?route=more");
}

export function loadTips() {
  return axios.get(baseURL + "/fairreisen.json?route=tip");
}

export function loadCategories() {
  return axios.get(baseURL + "/fairreisen.json?route=category");
}
